import React, { createContext, useState } from 'react';

export const UserContext = createContext(); // Correctamente exportado

export const UserProvider = ({ children }) => {
  const [User, setUser] = useState([]);

  const toggleUser = (rol, userName) => {
    // console.log(rol, userName);
    setUser([
      rol,
      userName
    ]);
  };

  return (
    <UserContext.Provider value={{ User, toggleUser }}>
      {children}
    </UserContext.Provider>
  );
};

