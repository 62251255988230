import React from 'react';
import { useParams } from 'react-router-dom';

function Reports() {
    const { id } = useParams();
    // console.log('ID:', id);

    return (
        <div>
            <p>El ID es: {id}</p>
            {/* Resto del contenido de la página */}
        </div>
    );
}

export default Reports;